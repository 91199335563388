import { Controller } from "stimulus";
const Rails = require("@rails/ujs");
let debounce = require("lodash/debounce");

export default class extends Controller {
  static targets = ["input", "form", "slider"];

  connect() {
    this.inputTargets.forEach((input) => {
      input.addEventListener("click", (event) => {
        Rails.fire(this.formTarget, "submit");
      });
    });
    if (this.hasSliderTarget) {
      this.sliderTarget.addEventListener(
        "updated",
        debounce(this.handleSliderChange, 300).bind(this)
      );
    }
  }

  handleSliderChange(event) {
    Rails.fire(this.formTarget, "submit");
  }
}
