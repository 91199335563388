import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tag", "input", "plus", "list", "dropdown", "validation"];

  connect() {
    const tags = this.tagTargets;
    tags.forEach((tag) => {
      tag.addEventListener("click", this.handleClick.bind(this));
    });
  }

  add(event) {
    if(event.type == "click" && this.plusTarget.value == "") { 
      this.validationTarget.classList.remove('hidden')
      event.preventDefault()
    } else if (event.type === "click" && this.plusTarget.value != "") {
      event.preventDefault();
      this.validationTarget.classList.add('hidden')
      this.appendToList(this.plusTarget.value);
      this.inputTarget.value =
        this.inputTarget.value + `, ${this.plusTarget.value}`;
      this.plusTarget.value = "";
    }
    if (event.key === "Enter") {
      event.preventDefault();

      this.appendToList(this.plusTarget.value);
      this.inputTarget.value =
        this.inputTarget.value + `, ${this.plusTarget.value}`;
      this.plusTarget.value = "";
    }
  }

  appendFromDropdown(event) {
    this.appendToList(event.currentTarget.dataset.tag);
    this.inputTarget.value = this.inputTarget.value + `, ${event.currentTarget.dataset.tag}`;

    this.dropdownTarget.querySelectorAll("input[type='checkbox'").forEach(checkBox => {
      checkBox.checked = false;
    })

    this.dropdownTarget.querySelectorAll("label.checked").forEach(label => {
      label.classList.remove('checked');
    })
    this.dropdownTarget.querySelector('.destination').innerHTML = "Kies uit eerder gebruikte tags";
  }

  appendToList(name) {
    this.listTarget.insertAdjacentHTML(
      "beforeend",
      `
        <li class="tag" data-name="${name}">
            ${name}
            <button data-action="click->tags#remove">
              <i class="far fa-times-circle"></i>
            </button>
        </li>
      `
    );
  }

  remove(event) {
    event.preventDefault();
    const tagClicked = event.currentTarget.parentElement;
    const allTags = this.inputTarget.value.split(", ");
    const indexForRemove = allTags.findIndex(
      (item) => item === tagClicked.dataset.name
    );
    allTags.splice(indexForRemove, 1);
    tagClicked.addEventListener("animationend", this.popOut);
    tagClicked.classList.add("leaving");
    this.inputTarget.value = allTags.join(", ");
  }

  popOut(event) {
    event.currentTarget.remove();
  }

  handleClick(event) {
    this.inputTarget.value =
      this.inputTarget.value + `, ${event.currentTarget.dataset.name}`;
    this.appendToList(event.currentTarget.dataset.name);
  }
}
