import { Controller } from "stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["content", "overlay"];

  insert(event) {
    let [data, status, xhr] = event.detail;
    this.closeOpened();
    this.overlayTarget.classList.add("open");
    document.body.addEventListener(
      "click",
      this.registerCloseIntent.bind(this)
    );
    const drawer = document.getElementById("drawer");
    drawer.innerHTML = xhr.response;
    setTimeout(() => {
      drawer.querySelector(".drawer-wrapper").classList.add("open");
    }, 10)
  }

  insertSub(event) {
    let [data, status, xhr] = event.detail;
    const drawer = document.getElementById("drawer-sub");
    drawer.insertAdjacentHTML('beforeend', xhr.response);
    setTimeout(() => {
      drawer.querySelector(".drawer-wrapper:last-child").classList.add("open");
    }, 10)
    flatpickr(".datepicker", {});
  }

  open(event) {
    event.preventDefault();
    const drawer = this.select(event);
    this.closeOpened();
    this.overlayTarget.classList.add("open");
    drawer.classList.add("open");
    document.body.addEventListener(
      "click",
      this.registerCloseIntent.bind(this)
    );
  }

  toggle(event) {
    event.preventDefault();
    const drawer = this.select(event);

    drawer.classList.toggle("open");
  }

  registerCloseIntent(event) {
    // Provision for dropzone
    if (event.target.classList.contains("dz-hidden-input") || event.target.classList.contains("dropzone")) {
      console.log('Dropzone is here')
    } else if (
      (!event.target.closest(".drawer-wrapper") &&
      !event.target.closest(".catalog-actions a") &&
      !event.target.closest(".filter-summary") &&
      !event.target.closest(".drawer-link")) ||
      (event.target.classList.contains("dz-hidden-input") || event.target.classList.contains("dropzone"))
    ) {
      const drawers = document.querySelectorAll(".drawer-wrapper.open");
      drawers.forEach((drawer) => drawer.classList.remove("open"));
      this.overlayTarget.classList.remove("open");
      document.body.removeEventListener("click", this.registerCloseIntent);
    }
  }

  closeWithButton(event) {
    event.preventDefault()
    const overlays = document.querySelectorAll('.overlay')
    const drawers = document.querySelectorAll('.drawer-wrapper.open')

    overlays.forEach(overlay => overlay.classList.remove('open'))
    drawers.forEach(drawer => drawer.classList.remove('open'))
    document.body.removeEventListener("click", this.registerCloseIntent);
  }

  backButton(event) {
    event.preventDefault()
    this.element.addEventListener('transitionend', event => {
      this.element.remove()
    })
    this.element.classList.remove('open');
  }

  closeOpened() {
    const drawers = document.querySelectorAll(".drawer-wrapper.open");
    drawers.forEach((drawer) => drawer.classList.remove("open"));
  }

  close(event) {
    event.preventDefault();
    const drawer = this.select(event);
    this.overlayTarget.classList.remove("open");
    drawer.classList.remove("open");
  }

  select(event) {
    const { drawerTarget } = event.currentTarget.dataset;
    const drawer = document.querySelector(`.drawer-wrapper#${drawerTarget}`);
    return drawer;
  }
}
