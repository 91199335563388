import { Controller } from "stimulus";

export default class extends Controller {
  switch(event) {
    const { displayTarget } = event.currentTarget.dataset;
    const items = document.getElementById("items");
    items.className = "";
    items.classList.add(displayTarget);
  }

  activate(element) {
    const siblings = document.querySelectorAll(".dropdown-item");
    siblings.forEach((sibling) => sibling.classList.remove("is-active"));
    element.classList.add("is-active");
  }

  toggleActive(event) {
    event.currentTarget.closest(".dropdown").classList.toggle("is-active");
  }

  toggleSelecting(event) {
    event.preventDefault();
    const icon = event.currentTarget.querySelector("i");
    const text = event.currentTarget.querySelector("p");
    if (icon.classList.contains("fa-ban") || icon.classList.contains("fa-gavel") || icon.classList.contains("fa-vote-yea")) {
      icon.classList = "fas fa-check-circle";
      text.innerText = "Selecteer";
    } else {
      icon.classList = "fas fa-ban";
      text.innerText = "Annuleer";
    }
    document
      .querySelector(".content-container")
      .classList.toggle("is-selecting");
  }
}
