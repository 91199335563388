import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dropdown"];

  toggleSub(event) {
    const dropdown = event.currentTarget.querySelector(".toggle-sub");
    if (dropdown.classList.contains("open")) {
      dropdown.classList.remove("open");
    } else {
      dropdown.classList.add("open");
      dropdown.style.top = `-${
        dropdown.querySelector("ul").offsetHeight + 28
      }px`;
    }
  }

  toggle(event) {
    if (this.dropdownTarget.classList.contains("open")) {
      this.dropdownTarget.classList.remove("open");
    } else {
      this.dropdownTarget.classList.add("open");
    }
  }
}
