import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slider", "min", "max", "outputMin", "outputMax"];

  handleSlide(event) {
    this.sliderTarget.value = event.currentTarget.value;

    const newEvent = new Event("updated");
    this.sliderTarget.dispatchEvent(newEvent);
  }

  handleMinMaxSlide(event) {
    const minSlider = this.minTarget;
    const maxSlider = this.maxTarget;

    const min = minSlider.min;
    const max = maxSlider.max;

    let pivot;

    if (event.target === minSlider) {
      if (minSlider.valueAsNumber >= Number(minSlider.max - 1)) {
        pivot = Math.min(max - 1, Number(minSlider.max) + 1);
      }
    }

    if (event.target === maxSlider) {
      if (maxSlider.valueAsNumber <= Number(maxSlider.min)) {
        pivot = Math.max(min, Number(maxSlider.min) - 2);
      }
    }

    if (pivot != null) {
      minSlider.max = pivot + 1;
      maxSlider.min = pivot + 1;
    }

    minSlider.style.flexGrow = this.stepsIn(minSlider);
    maxSlider.style.flexGrow = this.stepsIn(maxSlider);

    this.outputMinTarget.value = minSlider.value;
    this.outputMaxTarget.value = maxSlider.value;
  }

  stepsIn(slider) {
    return Number(slider.max) - Number(slider.min) + 1;
  }
}
