import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["count"];

  connect() {
    const items = document.querySelectorAll('.item-select:checked')
    items.forEach(item => {
      item.checked = false;
    })
  }

  count(event) {
    const previewImage = document.querySelector('.selection-image');
    const boxes = document.querySelectorAll(".item-select");
    previewImage.classList.remove('visible');
    let count = 0;
    boxes.forEach((box) => {
      if (box.checked) count++;
    });

    const imageUrl = document.querySelector(".item-select:checked ~ .item-image img");

    document.querySelector(".selection-count").innerHTML = `${count} geselecteerd`;
    if (imageUrl) {
      previewImage.src = imageUrl.src;
      previewImage.classList.add('visible');
    }

    this.countTarget.innerHTML = `${count} van `;
  }

  collectionSelect(event) {
    event.preventDefault();
    const { checked } = event.currentTarget.dataset;
    const boxes = document.querySelectorAll(".item-select");
    boxes.forEach((box) => (box.checked = checked));
    this.count();
  }
}
