import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["alert"];

  count(event) {
    const { countIdentifier, countable } = event.currentTarget.dataset
    const counter =  document.querySelector(countIdentifier)
    const count = document.querySelectorAll(countable).length
    counter.innerHTML = count;
  }
}
