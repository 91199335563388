import { Controller } from "stimulus"
import Dropzone from "dropzone"
// import 'dropzone/dist/dropzone.css'
// import 'dropzone/dist/basic.css'
import { DirectUpload } from "@rails/activestorage"
import Rails from "@rails/ujs"
// import { drop } from "lodash"

export default class extends Controller {
  static targets = ["input", "files"]

  connect() {
    // Setup Dropzone, with options
    Dropzone.autoDiscover = false
    this.inputTarget.disable = true
    this.inputTarget.style.display = "none"
    const dropzone = new Dropzone(this.element, {
      url: '/',
      maxFiles: this.filesTarget.dataset.singleFile == "true" ? 1 : 4,
      dictMaxFilesExceeded: "Je kunt geen bestanden meer uploaden.",
      dictRemoveFileConfirmation: "Weet je zeker dat je deze foto wilt verwijderen?",
      maxFilesize: '10',
      dictFileTooBig: "File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.",
      addRemoveLinks: true,
      dictRemoveFile : "×",
      resizeWidth: null,
      autoQueue: false,
      autoProcessQueue: false,
      // parallelUploads: 1,
      acceptedFiles:'.jpg,.png,.jpeg,.gif',
      dictInvalidFileType: "Je kunt dit type bestanden niet uploaden."
    })
    
    // Dynamically adjust maxFiles count
    let fileCountOnServer = (this.filesTarget.dataset.urls !== "") ? JSON.parse(this.filesTarget.dataset.urls).length : 0
    dropzone.options.maxFiles = dropzone.options.maxFiles - fileCountOnServer;

    // Adjust size of existing thumbnails -> Needed at this position
    dropzone.on('thumbnail', function(file, dataUrl) {
      var thumbs = document.querySelectorAll('.dz-image');
      [].forEach.call(thumbs, function (thumb) {
          var img = thumb.querySelector('img');
          if (img) {
              img.setAttribute('width', '100%');
              img.setAttribute('height', '100%');
          }
      });
    })

    // Get each existing image
    // Alternative that might work: https://stackoverflow.com/questions/30081416/dropzone-js-resize-image-to-fit-the-thumbnail-size
    if (this.filesTarget.dataset.urls) {
      JSON.parse(this.filesTarget.dataset.urls).forEach((image) => {
        // Create mockfile to user w dropzone displayExistingFile method
        let mockFile = { name: image.blob_name, size: image.blob_size, id: image.blob_id }
        
        // Transfer cloudinary URL to thumbnail URL
        let regex = new RegExp('[^/]+(?=/$|$)')
        let originalUrl = image.url
        let imageKey = regex.exec(originalUrl)[0]
        let cloudinaryCrop = "c_thumb,g_center,h_150,w_150/"
        let outputUrl = originalUrl.replace(regex, cloudinaryCrop)
        let finalUrl = outputUrl.concat(imageKey)

        // Dropzone specific methods -> Required
        let callback = null // Optional callback when it's done
        let crossOrigin = null // Added to the `img` tag for crossOrigin handling
        let resizeThumbnail = false // Tells Dropzone whether it should resize the image first
        dropzone.displayExistingFile(mockFile, finalUrl, callback, crossOrigin, resizeThumbnail)
        mockFile.previewTemplate.setAttribute('data-id', image.blob_id)
      })
    }

    // Adding new images
    dropzone.on("addedfile", file => {
      setTimeout(() => {
        if (file.accepted) {
          const upload = new DirectUpload(file, this.url)
          upload.create((error, blob) => {
            this.hiddenInput = document.createElement("input")
            this.hiddenInput.type = "hidden"
            this.hiddenInput.name = this.inputTarget.name
            this.hiddenInput.value = blob.signed_id
            this.inputTarget.parentNode.insertBefore(this.hiddenInput, this.inputTarget.nextSibling)
            dropzone.emit("success", file)
            file.previewTemplate.id = blob.id
            file.previewTemplate.setAttribute('data-new', "true")
            file.previewTemplate.setAttribute('data-id', blob.id)
            
            // Actually create a record, i.e. attachment
            // setTimeout(() => {
              let record = this.filesTarget.dataset.record
              let recordModel = this.filesTarget.dataset.recordModel
              let blob_id = blob.id
              let url = `/photo_uploads`  
              let data = new FormData()
              data.append("blob_id", blob_id)
              data.append("model", recordModel)
              data.append("record_id", record)
              
              // Covering some corner cases for catalogs
              this.specialCreateActions(file, record)

              Rails.ajax({
                url: url,
                type: "POST",
                data: data,
                success: function(data) { 
                  console.log("Yai, saved!")
                },
                error: function(data) { 
                  console.log(data)
                }
              })
              dropzone.emit("complete", file)
            // }, 1000)

          })
        }
      }, 500)
    })

    // Deleting files from data, if presisted
    dropzone.on("removedfile", file => {
      
      let id = file.previewElement.id !== "" ? parseInt(file.previewElement.id) : file.id
      let record = this.filesTarget.dataset.record
      let url = `/photo_uploads/${id}`
      let data = new FormData()
      data.append("blob_id", id)
      data.append("record_id", record)
      
      // Covering some corner cases for catalogs
      this.specialDeleteActions(file, record)

      // Create ajax request only if files have been accepted
      if (file.id || file.previewElement.id) {
        Rails.ajax({
          url: url,
          type: "DELETE",
          data: data,
          success: function(data) { 
            console.log("Yai, deleted!")
            dropzone.options.maxFiles += 1
          },
          error: function(data) { 
            alert(data)
          }
        })
      }
    });
  }

  specialCreateActions(file, record) {
    // Update cover img in catalogs menu
    let coverImg = document.querySelector(".catalog-cover .cover-img")
    if (coverImg && file.accepted) {
      coverImg.src = file.dataURL
    }

    // Replace catalog image in catalog index
    let catalog = document.querySelector(`#catalog_${record}`)
    if (catalog && file.accepted) {
      let catalogImg = catalog.querySelector(".catalog-cover-img")
      catalogImg.src = file.dataURL
      catalog.classList.remove("placeholder")
    }
  }

  specialDeleteActions(file, record) {
    // Remove hidden input for non-persisted records
    if (this.element.querySelector("input[type='hidden']")) {
      this.element.querySelector("input[type='hidden']").remove()
    }

    // Remove cover img in catalogs menu
    let cover = document.querySelector(".catalog-cover")
    if (cover && (file.accepted || file.id)) {
      let coverImg = cover.querySelector(".cover-img")
      let fallbackImg = cover.querySelector(".fallback-photo")
      let fallbackImgClone = fallbackImg.cloneNode(true)
      coverImg.remove()
      cover.insertAdjacentElement("afterbegin", fallbackImgClone)
      fallbackImgClone.classList.add("cover-img")
      fallbackImgClone.classList.remove("hidden")
    }

    // Replace catalog image in catalog index
    let catalog = document.querySelector(`#catalog_${record}`)
    
    if (catalog && (file.accepted || file.id)) {
      let catalogImg = catalog.querySelector(".catalog-cover-img")
      let catalogFallBackImg = catalog.querySelector(".fallback-photo")
      let catalogFallBackImgClone = catalogFallBackImg.cloneNode(true)
      catalog.classList.add("placeholder")
      catalogImg.remove()
      catalog.insertAdjacentElement("afterbegin", catalogFallBackImgClone)
      catalogFallBackImgClone.classList.add("catalog-cover-img")
      catalogFallBackImgClone.classList.remove("hidden")
      catalogFallBackImgClone.classList.remove("fallback-photo")
    }
  }

  get url() {
    return this.inputTarget.getAttribute('data-direct-upload-url')
  }

}
