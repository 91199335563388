import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["price", "frequency", "yearly", "monthly"] // "label", 

  connect() {
  }
  

  toggle() {
    if (this.priceTarget.innerHTML === "120") {
      this.frequencyTarget.innerHTML = "maand";
      this.labelTarget.classList.add("active");
      // Hide yearly button
      this.yearlyTarget.classList.add("hidden");
      // Show monthl button
      this.monthlyTarget.classList.remove("hidden")

      let repeater = setInterval(() => {
        count(this.priceTarget, repeater, 12, "up");
      }, 10);
    } else {
      this.labelTarget.classList.remove("active");
      // Hide monthly button
      this.monthlyTarget.classList.add("hidden")
      // Show yearly button
      this.yearlyTarget.classList.remove("hidden");
      let repeater = setInterval(() => {
        count(this.priceTarget, repeater, 120, "down");
      }, 10);
      this.frequencyTarget.innerHTML = "jaar";
    }
  }
}

const count = (counterItem, repeater, goalNumber, direction) => {
  const currentNumber = Number.parseInt(counterItem.innerHTML);
  if (direction === "down") {
    if (currentNumber < goalNumber) {
      counterItem.innerHTML = currentNumber + 1;
    } else {
      clearInterval(repeater);
    }
  } else {
    if (currentNumber > goalNumber) {
      counterItem.innerHTML = currentNumber - 1;
    } else {
      clearInterval(repeater);
    }
  }
};
