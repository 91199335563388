import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["popout"];

  connect() {
    this.popoutTargets.forEach((target, index) => {
      const instance = target.parentElement.dataset.instance;
      const popFullContainer = this.createPopout(target, instance);
      popFullContainer.id = `pop-${instance || 0}-${index}`;
      popFullContainer.classList.add(`instance-${instance}`);
      popFullContainer.dataset.index = index;
      target.parentElement.insertAdjacentElement("beforeend", popFullContainer);
    });
  }

  createPopout(target, instance) {
    const popFullContainer = document.createElement("div");
    popFullContainer.classList.add("popout-full");
    const popFull = document.createElement("img");
    popFull.src = target.src;
    popFullContainer.addEventListener("click", this.closePopout);
    // popFull.addEventListener("mouseleave", this.closePopout);
    popFullContainer.insertAdjacentElement("beforeend", popFull);
    return this.addNavigation(popFullContainer, instance);
  }

  addNavigation(popFullContainer, instance) {
    if (
      document.querySelectorAll(`.popout-image[data-instance='${instance}']`)
        .length > 1
    ) {
      const buttonContainer = document.createElement("div");
      buttonContainer.classList = "button-container";
      popFullContainer.insertAdjacentElement("beforeend", buttonContainer);
      buttonContainer.insertAdjacentElement(
        "beforeend",
        this.addPreviousButton(instance)
      );
      buttonContainer.insertAdjacentElement(
        "beforeend",
        this.addNextButton(instance)
      );
    }
    return popFullContainer;
  }

  addPreviousButton(instance) {
    const previousButton = document.createElement("button");
    const icon = document.createElement("i");
    const allPopFull = document.querySelectorAll(
      `.popout-image[data-instance='${instance}']`
    );
    icon.classList = `fas fa-arrow-left`;
    previousButton.addEventListener("click", (event) => {
      const currentIndex = Number.parseInt(
        event.currentTarget.closest(".popout-full").dataset.index
      );
      let previousIndex;
      if (currentIndex === 0) {
        previousIndex = allPopFull.length - 1;
      } else {
        previousIndex = currentIndex - 1;
      }
      const previous = allPopFull[previousIndex];
      document
        .querySelectorAll(".popout-full")
        .forEach((popOut) => popOut.classList.remove("open"));
      previous.querySelector(".popout-full").classList.add("open");
    });
    previousButton.insertAdjacentElement("beforeend", icon);
    return previousButton;
  }

  addNextButton(instance) {
    const nextButton = document.createElement("button");
    const icon = document.createElement("i");
    const allPopFull = document.querySelectorAll(
      `.popout-image[data-instance='${instance}']`
    );
    icon.classList = `fas fa-arrow-right`;
    nextButton.addEventListener("click", (event) => {
      const currentIndex = Number.parseInt(
        event.currentTarget.closest(".popout-full").dataset.index
      );
      let nextIndex;
      if (currentIndex === allPopFull.length - 1) {
        nextIndex = 0;
      } else {
        nextIndex = currentIndex + 1;
      }
      const next = allPopFull[nextIndex];
      document
        .querySelectorAll(".popout-full")
        .forEach((popOut) => popOut.classList.remove("open"));
      next.querySelector(".popout-full").classList.add("open");
    });
    nextButton.insertAdjacentElement("beforeend", icon);
    return nextButton;
  }

  closePopout(event) {
    event.currentTarget.closest(".popout-full").classList.remove("open");
  }

  popOpen(event) {
    event.currentTarget.nextElementSibling.classList.add("open");
  }
}
