import { Controller } from "stimulus";

export default class extends Controller {  
  deselect(event) {
    const item = document.querySelector(`#item-${this.element.dataset.item} input[type='checkbox']`);
    item.checked = false;
    this.element.parentElement.addEventListener('transitionend', event => {
      event.currentTarget.remove();
    })
    this.element.parentElement.classList.add('removing');
    const selectionTitle = document.querySelector('#selection-title');
    const selectedItems = document.querySelectorAll('.item-select:checked').length;
    selectionTitle.innerHTML = `${selectedItems} ${(selectedItems > 1 || selectedItems === 0) ? 'items' : 'item'} geselecteerd`;
    document.querySelector('.selection-count').innerHTML = `${selectedItems} geselecteerd`;
  }

}

