import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['content']

  replace(event) {
    let [data, status, xhr] = event.detail;
    const element = document.getElementById(event.currentTarget.dataset.replace)
    element.outerHTML = xhr.response
  }
}
