import { Controller } from "stimulus";

const algoliasearch = require("algoliasearch");
// var client = algoliasearch(ApplicationID, Search-Only-API-Key);
// const index = client.initIndex("auth_indices");

export default class extends Controller {
  static targets = ["value", "keys", "template", "results"];

  connect() {
    this.client = algoliasearch(keys.dataset.appId, keys.dataset.searchKey);
    this.index = this.client.initIndex(keys.dataset.index);
    this.templateString = this.templateTarget.querySelector(
      ".template"
    ).outerHTML;
  }

  submit(e) {
    e.preventDefault();
    this.index
      .search(this.valueTarget.value, {
        hitsPerPage: 10,
        page: 0,
      })
      .then((content) => {
        this.resultsTarget.innerHTML = "";
        content.hits.forEach((hit) => {
          const toInsert = this.insertIntoTemplate(hit);
          this.resultsTarget.insertAdjacentHTML("beforeend", toInsert);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  insertIntoTemplate(hit) {
    let template = `${this.templateString}`;
    Object.keys(hit).forEach((key) => {
      template = template.replace(`@${key}`, hit[key]);
    });
    const url = this.toUrl(hit.objectID);
    template = template.replace("@url", url);
    return template;
  }

  toUrl(string) {
    const url_elements = string.split("_");
    const id = url_elements.splice(-1, 1);
    const result = "/search/" + id + "/" + url_elements.join("_");
    return result;
  }
}
