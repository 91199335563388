import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["preview"];

  connect() {
    if (this.previewTarget.dataset.tooltip) {
      this.previewTarget.parentElement.querySelector(
        "label"
      ).innerHTML = `
      <div class="title-info">
        <h3 class="no-margin-bottom">
          ${this.previewTarget.dataset.title}
        </h3>
        <span data-balloon-length="medium" aria-label="${this.previewTarget.dataset.tooltip}" data-balloon-pos="left">
          <i class="fas fa-info"></i>
        </span>
      </div>
      `

    } else {
      this.previewTarget.parentElement.querySelector(
        "label"
      ).innerHTML = this.previewTarget.dataset.title;
    }
    this.previewTarget.parentElement.querySelector("label").insertAdjacentHTML(
      "beforeend",
      `
      <div class="collection">
        <div class="preview ${
          this.previewTarget.dataset.image !== undefined &&
          this.previewTarget.dataset.image !== ""
            ? "included thumbnail"
            : "absent"
        }" style="background-image:url('${this.previewTarget.dataset.image}');">
            <i class="fas fa-upload"></i>
        </div>
      </div>
      `
    );
    this.renderPresentImages();
  }

  renderPresentImages() {
    if (this.previewTarget.dataset.images === undefined) return;
    // console.log('step 1;')
    const collection = this.previewTarget.parentElement.querySelector(
      "label .collection"
    );
    if (!document.querySelector('.image-preview-catalog')) {
    JSON.parse(this.previewTarget.dataset.images).forEach((image) => {
      if (image.url.includes("placeholders")) return;
      // console.log('step 2;' + image.url)
      // console.log(image)
      const preview = document.createElement("div");
      preview.classList = `preview included thumbnail ${
        this.previewTarget.multiple ? "" : "single"
      }`;
      preview.style.backgroundImage = `url('${image.url}')`;
      preview.innerHTML = image.link;
      preview.addEventListener("click", this.handlePreviewClick);
      collection.insertAdjacentElement("afterbegin", preview);
    });

    }
  }

  handlePreviewClick(event) {
    event.preventDefault();
  }

  handleChange(event) {
    const collection = event.currentTarget.parentElement.querySelector(
      "label .collection"
    );
    document.querySelectorAll('.collection .thumbnail').forEach(thumbnail => {
      thumbnail.remove();
      // werkt, maar hij voegt hem later weer toe
    })
    Object.keys(event.currentTarget.files).forEach((key, index) => {
      const thumbnail = document.createElement("div");
      const reader = new FileReader();

      reader.onload = (readerEvent) => {

        // console.log(readerEvent);
        // console.log(readerEvent.target);
        // console.log(readerEvent.target.result);
        thumbnail.style.backgroundImage = `url('${readerEvent.target.result}')`;
        thumbnail.classList.add("thumbnail");
      };
      // console.log(event.currentTarget.files);
      collection.insertAdjacentElement("afterbegin", thumbnail);
      reader.readAsDataURL(event.currentTarget.files[key]);
    });
    this.renderPresentImages();
  }
}
