import { Controller } from "stimulus";
const Rails = require("@rails/ujs");

export default class extends Controller {
  static targets = ["form"];
  
  submit(event) {
    Rails.fire(this.formTarget, "submit");
  }

}


