import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["drawer"];

  prepareClose(event) {
    if (
      event.target.closest(".drawer-wrapper") ||
      event.target.closest(".tree-branch")
    ) {
      return;
    }
    document.querySelector(".drawer-wrapper").classList.remove("open");
    document.body.removeEventListener("click", this.prepareClose);
  }

  patchForm(form) {
    const patch = form.querySelector("[value=patch]");
    if (patch) return;
    form.insertAdjacentHTML(
      "afterbegin",
      `<input type="hidden" name="_method" value="patch">`
    );
  }

  open(name) {
    name.focus();
    this.drawerTarget.classList.add("open");
    document.body.addEventListener("click", this.prepareClose);
  }

  new(event) {
    const form = this.drawerTarget.querySelector("form");
    const name = form.querySelector("#category_name");
    const data = event.currentTarget.dataset;
    const patch = form.querySelector("[value=patch]");
    patch ? patch.remove() : "";
    name.value = "";
    this.drawerTarget.querySelector("#category_parent_id").value = data.parent;
    form.action = `/catalogs/${data.catalog}/categories/`;
    this.open(name);
  }

  edit(event) {
    const form = this.drawerTarget.querySelector("form");
    const name = form.querySelector("#category_name");
    const data = event.currentTarget.dataset;
    this.patchForm(form);
    this.drawerTarget.querySelector("#category_parent_id").value = data.parent;
    name.value = data.name;
    form.action = `/catalogs/${data.catalog}/categories/${data.category}`;
    this.open(name);
  }
}
