import { Controller } from "stimulus";
const Rails = require("@rails/ujs");

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.element.querySelectorAll("input[type='radio']").forEach(radio => {
      radio.addEventListener('input', event => {
        this.inputTarget.value = radio.value;
      })
    })
  }

}


