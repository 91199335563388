import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["accordion", "panel", "collapse"];

  connect() {
    
    // Conditional required to not brake accordions where there is no expand all button
    if (document.getElementById("accordionAll")) {
      
      // ACCORDION ALL
      var collapseBtn = this.collapseTarget;

      // Add eventlistener to collapse all button
      collapseBtn.addEventListener("click", collapseAllAccordions)

      function collapseAllAccordions(){
        
        // All accordions
        var accs = document.getElementsByClassName("accordion")
        
        // Toggle button chevron
        collapseBtn.classList.toggle("active");
        var iconClass = this.querySelector(".fas").classList;
        
        // If accordions open?
        // if (iconClass.contains("fa-angle-double-down")) {
          if (collapseBtn.classList.contains("active")) {
            collapseBtn.querySelector("span").innerHTML = "Alles invouwen"
            
            // Swith icon to up
            iconClass.replace("fa-angle-double-down", "fa-angle-double-up")
            collapseBtn.classList.add("active");
          
          Array.from(accs).forEach((gridAccordion) => {
            var iconClass = gridAccordion.querySelector(".fas").classList;
            var panel = gridAccordion.lastElementChild;
          
            // Add Active class to all accordions - expand all panes
            if (gridAccordion.classList.contains("active")) {
              // That's great, do nothing 
            } else {
              gridAccordion.classList.add("active")
              panel.style.maxHeight = panel.scrollHeight + "px"
              iconClass.replace("fa-chevron-down", "fa-chevron-up")
            }
          })
        
        } else {
        
          // Switch icon to up
          iconClass.replace("fa-angle-double-up", "fa-angle-double-down")
          collapseBtn.querySelector("span").innerHTML = "Alles uitvouwen"
        
          Array.from(accs).forEach((gridAccordion) => {
            var iconClass = gridAccordion.querySelector(".fas").classList; 
            var panel = gridAccordion.lastElementChild;
            
            // Collapse all panels
            if (gridAccordion.classList.contains("active")) {
              gridAccordion.classList.remove("active")
              panel.style.maxHeight = null
              iconClass.replace("fa-chevron-up", "fa-chevron-down")
            }

          })
        }

      }
      
      /* Make default all panels open */
      collapseBtn.classList.add("active")
      collapseBtn.querySelector("#icon").classList.replace("fa-angle-double-down", "fa-angle-double-up")
      collapseBtn.querySelector("span").innerHTML = "Alles invouwen"
      
      // ACCORDION  
      var acc = this.accordionTargets;
      var i;
      
      acc.forEach(element => {
        /* Add to all elements active class */
        element.classList.add("active")
        element.parentElement.classList.add("active")
        
        /* Open all panels by default */
        var iconClass = element.querySelector("#icon").classList
        var panel = element.nextElementSibling;
        // if (panel.style.maxHeight) {
          // panel.style.maxHeight = null
          // iconClass.replace("fa-chevron-down", "fa-chevron-up")
        // } else {
          panel.style.maxHeight = panel.scrollHeight + "px"
          iconClass.replace("fa-chevron-down", "fa-chevron-up")
        // }
      });
    
    } else {
      
      var acc = this.accordionTargets;
      var i;

    }

    /* Toggle between adding and removing the "active" class */
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function() {
        /* Toggle between adding and removing the "active" class,
        to highlight the button that controls the panel */
        this.classList.toggle("active")
    
        /* Toggle between hiding and showing the active panel */
        var iconClass = this.querySelector("#icon").classList
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null
          iconClass.replace("fa-chevron-up", "fa-chevron-down")
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px"
          iconClass.replace("fa-chevron-down", "fa-chevron-up")
        }
      })
    }

  }
}