import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "button"];

  connect() {
    // console.log(this.checkboxTarget)
    // this.buttonTarget.addEventListener('click', this.handleClick)
  }

  handleClick(event) {
    if(!this.checkboxTarget.checked) {
      event.preventDefault();
      this.buttonTarget.dataset.balloonVisible = true;
      this.buttonTarget.dataset.balloonPos = "right";
      this.buttonTarget.ariaLabel = "Accepteer alstublieft de uCatalog voorwaarden";
    } 
  }
}

