import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu", "destination"];

  connect() {
    if (this.menuTarget.attributes.include_blank) {
      const instanceNumber = [
        ...document.querySelectorAll(".dropdown-select .destination"),
      ].findIndex((item) => item === this.destinationTarget);
      this.menuTarget.insertAdjacentHTML(
        "beforeend",
        `
        <span class="radio">
        <label for="item_location_id_${instanceNumber}">
        <input class="radio_buttons optional" placeholder="Geen" type="radio" value="nil" name="item[location_id]" id="item_location_id_${instanceNumber}">
        Geen
        </label>
        </span>
        `
        );
      }

      const inputs = this.menuTarget.querySelectorAll("label input");
      inputs.forEach((input) => {
        input.addEventListener("input", (event) => {
          this.removeOtherChecked(inputs);
          
          if (event.currentTarget.checked) {
            this.setParentChecked(event.currentTarget);
            const displayText =
            typeof Number.parseInt(event.currentTarget.value) === "number"
            ? event.currentTarget.parentElement.innerText
            : event.currentTarget.value;
            this.destinationTarget.innerHTML = displayText;
            this.handleDrop(event);
          }
          if (event.currentTarget.value === "nil") {
            inputs.forEach((input) => (input.checked = false));
          }
        });
        if (input.checked) {
          this.setParentChecked(input);
        }
      });
    // Remove initial 'checked' when new user is created
    if (this.element.dataset.newUser == "true") {
      this.removeOtherChecked(inputs)
    }
    document.body.addEventListener("click", this.handleClosing.bind(this));
  }

  handleClosing(event) {
    if (!event.target.closest(".dropdown-select")) {
      this.menuTarget.classList.remove("open");
    }
  }

  removeOtherChecked(inputs) {
    inputs.forEach((input) => {
      input.parentElement.classList.remove("checked");
    });
  }

  setParentChecked(input) {
    input.parentElement.classList.add("checked");
  }

  handleDrop(event) {
    event.preventDefault();
    if (this.menuTarget.classList.contains("open")) {
      this.menuTarget.classList.remove("open");
    } else {
      this.menuTarget.classList.add("open");
    }
  }
}
