import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["label"];

  connect() {
    if(document.querySelector('.choice-radio input:checked')) {
      this.labelTarget.innerHTML = document.querySelector('.choice-radio input:checked').dataset.text;
    }
  }

  // handleHeart(event) {
  //   let faHeart = document.querySelector('.choice-radio .fa-heart').parentElement
  //   let slider = document.querySelector('.choice-slider')

  //   if (document.querySelector('.choice-radio input.radio-red:checked ~ label')) {
  //           slider.classList.toggle('hidden');
  //   }
  //   this.handleInput(event);
  // }
  handleInput(event) {
    this.labelTarget.innerHTML = event.currentTarget.dataset.text;

    if (document.querySelector('.choice-radio input.radio-red:checked ~ label')) {
      document.querySelector('.choice-slider').classList.remove('hidden');
    } else {
      document.querySelector('.choice-slider').classList.add('hidden');
    }
  }

}

