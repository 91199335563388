import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dots"];
  
  handleScroll(event) {
    const position = Math.floor(event.currentTarget.scrollLeft / event.currentTarget.offsetWidth);
    const dots = [...this.dotsTarget.querySelectorAll('.dot-nav')];
    const activeDot = dots[position]
    dots.forEach(dot => {
      dot.classList.remove('active');
    })
    activeDot.classList.add('active')
  }

  handleClick(event) {
    console.log('clicked')
    document.querySelector(event.currentTarget.dataset.photo).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
  }

  handleMagnify(event) {
    document.querySelector(document.querySelector('.dot-nav.active').dataset.photo).querySelector('img').click();
  }

}

