import { Controller } from "stimulus";

export default class extends Controller {
  toggleOpen(event) {
    event.preventDefault();
    document
      .querySelector(event.currentTarget.dataset.section)
      .classList.add("open");
  }

  toggleClose(event) {
    event.preventDefault();
    event.currentTarget
      .closest(".drawer-slide-section")
      .classList.remove("open");
  }
}
