import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["wrapper"];

  connect() {
    this.wrapperTarget
      .querySelectorAll(".tree-checkbox")
      .forEach((checkBox) => {
        checkBox.addEventListener("change", this.handleChange.bind(this));
      });
  }

  handleChange(event) {
    this.wrapperTarget
      .querySelectorAll(".tree-checkbox")
      .forEach((checkBox) => {
        checkBox.checked = false;
      });
    event.currentTarget.checked = true;
  }
}
