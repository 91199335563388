import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slider"];

  handleChange(event) {
    if (event.currentTarget.dataset.range) {
      this.sliderTarget.classList.remove("hidden");
    } else {
      this.sliderTarget.classList.add("hidden");
    }
  }
}
