import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggle", "sub"];

  toggleOpen(event) {
    this.toggleTarget.classList.add("open");
  }
  toggleClose(event) {
    if (event.target.classList.contains("sidebar") || event.currentTarget.id === "close-button") {
      this.toggleTarget.classList.remove("open");
    }
  }
  toggleSub(event) {
    const subList = [...this.subTargets].find(
      (item) => item.id == event.currentTarget.dataset.id
    );

    if (subList.classList.contains("open")) {
      subList.classList.remove("open");
    } else {
      subList.classList.add("open");
    }
  }
}
