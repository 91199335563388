import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item"];
  
  toggle(event) {
    if(this.element.hasAttribute("data-balloon-visible")) {
      this.element.removeAttribute("data-balloon-visible")
      this.element.removeAttribute("data-balloon-pos")
    } else {
      this.element.setAttribute("data-balloon-visible", "true")
      this.element.setAttribute("data-balloon-pos", "up")
    }
  }

}

