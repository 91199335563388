import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["alert"];

  closeAlert(event) {
    const alert = event.currentTarget.closest(".alert");
    alert.addEventListener("animationend", (event) => {
      event.currentTarget.remove();
    });
    const drawerBody = event.currentTarget.closest('.has-alert');
    if (drawerBody) {
      drawerBody.classList.remove('has-alert');
    }
    alert.classList.add("go-away");
    alert.style.marginTop = `-${alert.offsetHeight + 15}px`;
  }
}
