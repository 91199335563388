import { Controller } from "stimulus"
import { Sortable } from "sortablejs"
import Rails from "@rails/ujs"

export default class extends Controller {

  connect() {
    // Initialse SortableJS with handle option
    if (this.element.getElementsByClassName("handle").length > 0) {
      this.sortable = Sortable.create(this.element, {
        animation: 150,
        handle: ".handle", 
        onEnd: this.end.bind(this)
      })
    } else {
      // Initialse SortableJS without handle option
      this.sortable = Sortable.create(this.element, {
        animation: 150,
        onEnd: this.end.bind(this)
      })
    }
  }

  end(event) {
    let id = event.item.dataset.id
    
    let url = this.data.get("url")
    url = url.replace(":id", id)
    
    let data = new FormData()
    
    // Condition only for dropzone image upload to see if image is new or existing
    let counter = -1
    event.from.querySelectorAll(".dz-preview").forEach((element)=> {
      if (element.dataset.new == "true") {
        counter += 1 
      }
      return counter
    })

    // Append newIndex to params
    if (event.item.dataset.new == "true") {
      data.append("position", event.newIndex - counter)
    } else {
      data.append("position", event.newIndex + 1)
    }

    console.log(`New index: ${event.newIndex}`)
    console.log(`Old index: ${event.oldIndex}`)

    Rails.ajax({
      url: url,
      type: 'PATCH',
      data: data
    })
  }

}