import { Controller } from "stimulus";
const Rails = require("@rails/ujs");

export default class extends Controller {
  static targets = ["slider", "button", "form"];

  connect() {
    this.deselectListeners();
  }

  selectCategoryChildren(event) {
    const parent = event.currentTarget;
    
    if (Number.parseInt(parent.dataset.childrenCount, 0) === 0) {
      return;
    }
    
    const checkboxes = parent
    .closest(".checkbox-group")
    .querySelector(".tree-children")
    .querySelectorAll("input");
    
    checkboxes.forEach((checkbox) => {
      checkbox.checked = parent.checked;
    });
  }

  deselectListeners() {
    const elements = document.querySelectorAll("button.deselect");

    elements.forEach((element) => {
      element.addEventListener("click", (e) => {
        e.preventDefault();
        e.currentTarget.parentElement
          .querySelectorAll("input")
          .forEach((input) => {
            input.checked = false;
          });

        this.previewResultCount({
          currentTarget: this.formTarget,
          srcElement: {
            dataset: {},
          },
        });
      });
    });
  }

  async previewResultCount(event) {
    const form = event.currentTarget;
    if (event.srcElement.dataset.dontFilter) return;
    
    form.setAttribute("data-remote", "true");
    Rails.fire(form, "submit");
    form.removeAttribute("data-remote");
  }

  async setCount(event) {
    let [data, status, xhr] = event.detail;
    this.buttonTarget.innerHTML = xhr.response;
  }
}
