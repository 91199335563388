import { Controller } from "stimulus";
const Rails = require("@rails/ujs");

export default class extends Controller {
  
  remoteSubmit(event) {
    event.preventDefault();
    const form = document.querySelector(this.element.dataset.form);
    Rails.fire(form, "submit");
  }

}


